import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useMemo, useEffect } from 'react';

import {
  EdgeGroupListItemResponse,
  useEdgeGroups,
} from '@/react/edge/edge-groups/queries/useEdgeGroups';
import { grammaticallyJoin } from '@/portainer/helpers/strings';

import { TextTip } from '@@/Tip/TextTip';

import { usePreviousVersions } from '../queries/usePreviousVersions';

import { FormValues } from './types';
import { useEdgeGroupsEnvironmentIds } from './useEdgeGroupsEnvironmentIds';

export function RollbackOptions() {
  const {
    values: { groupIds },
  } = useFormikContext<FormValues>();

  const { isLoading, previousVersionCount, version, versionError } =
    useSelectVersionOnMount();

  const groupsQuery = useEdgeGroups({
    select: (edgeGroups) => selectGroupNamesAndEnvCount(edgeGroups, groupIds),
  });
  const { groupNames, environmentCount } = groupsQuery.data ?? {};

  if (versionError) {
    return <TextTip>{versionError}</TextTip>;
  }

  if (!previousVersionCount) {
    return (
      <TextTip>
        There are no rollback options available for your selected groups(s)
      </TextTip>
    );
  }

  if (isLoading || groupsQuery.isLoading || !groupNames) {
    return null;
  }

  return (
    <div className="form-group">
      <div className="col-sm-12">
        {environmentCount ?? 0} edge device(s) from {groupNames} will rollback
        to version {version}
      </div>
    </div>
  );
}

function useSelectVersionOnMount() {
  const {
    values: { groupIds, version },
    setFieldValue,
    setFieldError,
    errors: { version: versionError },
  } = useFormikContext<FormValues>();

  const environmentIdsQuery = useEdgeGroupsEnvironmentIds(groupIds);

  const previousVersionsQuery = usePreviousVersions(
    environmentIdsQuery.data ?? []
  );

  const previousVersions = useMemo(
    () =>
      previousVersionsQuery.data
        ? _.uniq(Object.values(previousVersionsQuery.data))
        : [],
    [previousVersionsQuery.data]
  );

  const previousVersionCount = useMemo(
    () => Object.keys(previousVersions).length,
    [previousVersions]
  );

  useEffect(() => {
    switch (previousVersions.length) {
      case 0:
        setFieldValue('version', '');
        setFieldError('version', 'No rollback options available');
        break;
      case 1:
        setFieldValue('version', previousVersions[0]);
        break;
      default:
        setFieldError(
          'version',
          'Rollback is not available for these edge group as there are multiple version types to rollback to'
        );
    }
  }, [previousVersions, setFieldError, setFieldValue]);

  return {
    isLoading: previousVersionsQuery.isLoading,
    versionError,
    version,
    previousVersionCount,
  };
}

function selectGroupNamesAndEnvCount(
  edgeGroups: EdgeGroupListItemResponse[],
  groupIds: number[]
) {
  const selectedEdgeGroups = edgeGroups.filter((g) => groupIds.includes(g.Id));
  const groupNames = grammaticallyJoin(selectedEdgeGroups.map((g) => g.Name));
  // there may be environments in multiple groups, so get the unique count
  const edgeGroupEnvironments = selectedEdgeGroups.flatMap((g) => g.Endpoints);
  const environmentCount = new Set(edgeGroupEnvironments).size;
  return { groupNames, environmentCount };
}
