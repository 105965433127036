import { object, number, array, SchemaOf, string } from 'yup';

import { EnvironmentMetadata } from '@/react/portainer/environments/environment.service/create';
import { KUBE_STACK_NAME_VALIDATION_REGEX } from '@/react/kubernetes/DeployView/StackName/constants';

export function metadataValidation(): SchemaOf<EnvironmentMetadata> {
  return object({
    groupId: number(),
    tagIds: array().of(number()).default([]),
    customTemplateId: number().default(0),
    variables: array(),
    customTemplateContent: string().default(''),
    stackName: string().matches(
      KUBE_STACK_NAME_VALIDATION_REGEX,
      "Stack must consist of alphanumeric characters, '-', '_' or '.', must start and end with an alphanumeric character and must be 63 characters or less (e.g. 'my-name', or 'abc-123')."
    ),
  });
}
