import _ from 'lodash-es';
import { RepositoryTagViewModel } from '../models/repositoryTag';

angular.module('portainer.registrymanagement').factory('RegistryV2Helper', [
  function RegistryV2HelperFactory() {
    'use strict';

    var helper = {};

    // convert image configs blob history to manifest v1 history
    function parseImageConfigsHistory(imageConfigs, v2) {
      return _.map(imageConfigs.history.reverse(), (item) => {
        item.CreatedBy = item.created_by;

        // below fields exist in manifest v1 history but not image configs blob
        item.id = v2.config.digest;
        item.created = imageConfigs.created;
        item.docker_version = imageConfigs.docker_version;
        item.os = imageConfigs.os;
        item.architecture = imageConfigs.architecture;
        item.config = imageConfigs.config;
        item.container_config = imageConfigs.container_config;

        return item;
      });
    }

    helper.manifestsToTag = function (manifests) {
      var v2 = manifests.v2;
      var imageConfigs = manifests.imageConfigs;

      var history = [];
      var name = '';
      var os = '';
      var arch = '';

      if (imageConfigs) {
        // use info from image configs blob when manifest v1 is not provided by registry
        os = imageConfigs.os || '';
        arch = imageConfigs.architecture || '';
        history = parseImageConfigsHistory(imageConfigs, v2);
      }

      var size = v2.layers.reduce(function (a, b) {
        return {
          size: a.size + b.size,
        };
      }).size;

      var imageId = v2.config.digest;

      // v2.digest comes from
      //  1. Docker-Content-Digest header from the v2 response, or
      //  2. Calculated locally by sha256(v2-response-body)
      var imageDigest = v2.digest;

      return new RepositoryTagViewModel(name, os, arch, size, imageDigest, imageId, v2, history);
    };

    return helper;
  },
]);
