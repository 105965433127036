import { ChangeEvent, useMemo, useEffect } from 'react';
import { useField } from 'formik';

import { CustomTemplate } from '@/react/portainer/templates/custom-templates/types';
import {
  CustomTemplatesVariablesField,
  VariablesFieldValue,
  getVariablesFieldDefaultValues,
} from '@/react/portainer/custom-templates/components/CustomTemplatesVariablesField';
import { renderTemplate } from '@/react/portainer/custom-templates/components/utils';
import { getCustomTemplateFile } from '@/react/portainer/templates/custom-templates/queries/useCustomTemplateFile';
import { StackName } from '@/react/kubernetes/DeployView/StackName/StackName';
import { useSettings } from '@/react/portainer/settings/queries';

import { FormControl } from '@@/form-components/FormControl';
import { Select, Option } from '@@/form-components/Input/Select';
import { FormSection } from '@@/form-components/FormSection';
import { TextTip } from '@@/Tip/TextTip';

import { useSetAvailableOption } from '../WizardKaaS/useSetAvailableOption';
import { BetaAlert } from '../../../update-schedules/common/BetaAlert';

interface Props {
  customTemplates: CustomTemplate[];
}

export function CustomTemplateSelector({ customTemplates }: Props) {
  const settingsQuery = useSettings();
  const hideStacks =
    !!settingsQuery.data?.GlobalDeploymentOptions?.hideStacksFunctionality;
  const customTemplateOptions: Option<number>[] = useMemo(() => {
    if (customTemplates.length === 0) {
      return [{ value: 0, label: 'No Custom Templates available' }];
    }
    const options = customTemplates.map((c) => ({
      value: c.Id,
      label: `${c.Title} - ${c.Description}`,
    }));
    return [{ value: 0, label: 'Select a Custom Template' }, ...options];
  }, [customTemplates]);

  const [stackNameProps, stackNameMeta, stackNameHelper] =
    useField<string>('meta.stackName');

  const [fieldProps, meta, helpers] = useField<number>('meta.customTemplateId');
  useSetAvailableOption(
    customTemplateOptions,
    fieldProps.value,
    'customTemplateId'
  );

  const [templateVariables, , templateVariableHelpers] =
    useField<VariablesFieldValue>('meta.variables');
  const [, , customTemplateContentHelpers] = useField<string>(
    'meta.customTemplateContent'
  );

  const selectedTemplate = customTemplates.find(
    (c) => c.Id === fieldProps.value
  );

  useEffect(() => {
    const value = getVariablesFieldDefaultValues(
      selectedTemplate?.Variables || []
    );
    templateVariableHelpers.setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);

  useEffect(() => {
    async function fetchTemplate() {
      if (selectedTemplate) {
        const fileContent = await getCustomTemplateFile({
          id: selectedTemplate.Id,
          git: !!selectedTemplate.GitConfig,
        });
        const template = renderTemplate(
          fileContent,
          templateVariables.value,
          selectedTemplate?.Variables || []
        );
        customTemplateContentHelpers.setValue(template);
      }
    }

    fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateVariables.value, selectedTemplate]);

  return (
    <FormSection title="Initial deployment">
      <div className="flex flex-col gap-2 w-full mb-4">
        <BetaAlert message="Beta feature - so far, this has only been tested on a limited set of Kubernetes environments" />
      </div>

      {hideStacks ? (
        <TextTip color="blue" className="mb-4">
          Namespaces specified in the manifest will be used.
        </TextTip>
      ) : (
        <>
          <TextTip color="blue" className="mb-4">
            <p className="mb-1">
              Enter or select a &apos;stack&apos; name to group multiple
              deployments together, or else leave empty to ignore.
            </p>
            <p>Namespaces specified in the manifest will be used.</p>
          </TextTip>

          <StackName
            stackName={stackNameProps.value}
            setStackName={handleStackNameChange}
            inputClassName="col-sm-9 col-lg-10"
            textTip=""
            error={stackNameMeta.error}
          />
        </>
      )}

      <FormControl
        label="Custom template"
        tooltip={
          <>
            <div>
              Select a custom template to be installed when the environment
              first connects. A template manifest can add a namespace and
              applications to be deployed there, or just deploy to the default
              namespace.
            </div>
            {customTemplateOptions.length <= 1 && (
              <div className="mt-2">
                You don&apos;t currently have any, but you can create them via
                the Custom Templates menu option, once you have at least one
                Kubernetes environment set up.
              </div>
            )}
          </>
        }
        inputId="kaas-customtemplate"
        errors={meta.error}
      >
        <Select
          name={fieldProps.name}
          id="kaas-customtemplate"
          data-cy="kaasCreateForm-customtemplateSelect"
          disabled={customTemplateOptions.length <= 1}
          options={customTemplateOptions}
          value={fieldProps.value}
          onChange={handleChange}
        />

        {selectedTemplate && (
          <CustomTemplatesVariablesField
            value={templateVariables.value}
            definitions={selectedTemplate?.Variables}
            onChange={handleTemplateVariablesChange}
          />
        )}
      </FormControl>
    </FormSection>
  );

  function handleTemplateVariablesChange(v: VariablesFieldValue) {
    templateVariableHelpers.setValue(v);
  }

  function handleChange(e: ChangeEvent<HTMLSelectElement>) {
    const value = parseInt(e.target.value, 10);
    if (!Number.isNaN(value)) {
      helpers.setValue(value);
    }
  }

  function handleStackNameChange(name: string) {
    stackNameHelper.setValue(name);
  }
}
